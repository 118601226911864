<template>
  <section class="graphic-statistics">
    <img src="../../../assets/images/temporary/图标统计.png" alt="">
  </section>
</template>

<script>
export default {
  name: "graphic-statistics"
};
</script>

<style lang="scss" scoped>
.graphic-statistics {
  img {
    width: 100%;
    height: 100%;
    position: relative;
    top: VH(-100px);
  }
}
</style>
