<template>
     <section class="statement-statistics"></section>
</template>

<script>
    export default {
        name: "statement-statistics"
    };
</script>

<style scoped>

</style>
